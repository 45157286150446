<template>
  <div>
    <v-row justify="center" class="my-4">
      <v-col cols="12" class="px-4">
        <h2>
          Meine Besuche
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center" class="my-4" v-if="besuche.length == 0">
      <v-col cols="12">
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl pb-0"
        >
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <h3>
                Du hast in den letzten 4 Wochen keine Veranstaltungen besucht.
              </h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-4" v-if="besuche.length > 0">
      <v-col cols="12" v-for="b in besuche" :key="b.id">
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl pb-0"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card
                class="rounded-t-xl"
                :color="template.getVerband(b.verein.verband).color"
                :style="`color: ${
                  template.getVerband(b.verein.verband).textcolor
                };`"
              >
                <v-row class="px-4">
                  <v-col cols="auto" align-self="center" class="pb-0">
                    <v-badge avatar bordered overlap>
                      <template v-slot:badge>
                        <v-avatar>
                          <v-img
                            :src="template.getVerband(b.verein.verband).logo"
                          ></v-img>
                        </v-avatar>
                      </template>

                      <v-avatar size="60">
                        <v-icon
                          size="60"
                          :color="
                            template.getVerband(b.verein.verband).textcolor
                          "
                        >
                          mdi-ticket
                        </v-icon>
                      </v-avatar>
                    </v-badge>
                  </v-col>
                  <v-col class="pb-0">
                    <v-row>
                      <v-col
                        cols="auto"
                        class="text-center"
                        align-self="center"
                        v-if="b.veranstaltung.id != 'ANDERE'"
                      >
                        <v-icon
                          x-large
                          :color="template.colors.block_text"
                          v-if="b.veranstaltung.art == 'Training'"
                        >
                          mdi-strategy
                        </v-icon>
                        <v-icon
                          x-large
                          v-if="b.veranstaltung.art == 'Wettkampf'"
                          :color="template.colors.block_text"
                        >
                          mdi-podium
                        </v-icon>
                        <v-icon
                          x-large
                          v-if="b.veranstaltung.art == 'Freundschaftsspiel'"
                          :color="template.colors.block_text"
                        >
                          mdi-soccer
                        </v-icon>
                        <v-icon
                          x-large
                          v-if="b.veranstaltung.art == 'Meisterschaftsspiel'"
                          :color="template.colors.block_text"
                        >
                          mdi-soccer-field
                        </v-icon>
                        <v-icon
                          x-large
                          v-if="b.veranstaltung.art == 'Versammlung'"
                          :color="template.colors.block_text"
                        >
                          mdi-calendar-star
                        </v-icon>
                      </v-col>
                      <v-col v-if="b.veranstaltung.id == 'ANDERE'">
                        <h3 class="font-weight-light">
                          Andere Veranstaltung
                        </h3>
                        <h2 class="font-weight-bold">
                          {{ b.verein.name }}
                        </h2>
                      </v-col>
                      <v-col v-if="b.veranstaltung.id != 'ANDERE'">
                        <h3 class="font-weight-light">
                          {{ b.veranstaltung.art }}
                        </h3>
                        <h2>
                          <span
                            class="font-weight-bold"
                            v-if="b.veranstaltung.art == 'Training'"
                          >
                            {{ b.veranstaltung.mannschaft }}
                          </span>
                          <span
                            class="font-weight-bold"
                            v-if="
                              b.veranstaltung.art == 'Wettkampf' ||
                              b.veranstaltung.art == 'Versammlung'
                            "
                          >
                            {{ b.veranstaltung.name }}
                          </span>
                          <span
                            class="font-weight-bold"
                            v-if="
                              b.veranstaltung.art == 'Freundschaftsspiel' ||
                              b.veranstaltung.art == 'Meisterschaftsspiel'
                            "
                          >
                            <small>
                              {{ b.veranstaltung.mannschaft }} -
                              {{ b.veranstaltung.gastmannschaft }}
                            </small>
                          </span>
                        </h2>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-2 px-4" v-if="b.weitere_personen">
            <v-col
              cols="6"
              class="text-center"
              align-self="center"
              v-if="b.weitere_personen.length > 0"
            >
              <small>
                WEITERE PERSONEN
              </small>
              <h3 v-for="(person, index) in b.weitere_personen" :key="index">
                {{
                  person.vorname
                    ? person.vorname + ' ' + person.nachname
                    : person
                }}
              </h3>
            </v-col>
            <v-col
              cols="6"
              class="text-center"
              align-self="center"
              v-if="b.weitere_personen.length > 0"
            >
              <span>
                Zutritt:
                <b>
                  {{ parseDate1(b.zutritt.datum) }} -
                  {{ b.zutritt.uhrzeit }} UHR
                </b>
                <br />
                Verlassen:
                <b>
                  {{ parseDate1(b.verlassen.datum) }} -
                  {{ b.verlassen.uhrzeit }} UHR
                </b>
              </span>
            </v-col>
            <v-col
              cols="4"
              class="text-center"
              align-self="center"
              v-if="b.weitere_personen.length == 0"
            >
              <span>
                Datum:
                <br />
                <b>{{ parseDate1(b.zutritt.datum) }}</b>
              </span>
            </v-col>
            <v-col
              cols="4"
              class="text-center"
              align-self="center"
              v-if="b.weitere_personen.length == 0"
            >
              <span>
                Zutritt:
                <br />
                <b>{{ b.zutritt.uhrzeit }} UHR</b>
              </span>
            </v-col>
            <v-col
              cols="4"
              class="text-center"
              align-self="center"
              v-if="b.weitere_personen.length == 0"
            >
              <span>
                Verlassen:
                <br />
                <b>{{ b.verlassen.uhrzeit }} UHR</b>
              </span>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-2 px-4" v-if="!b.weitere_personen">
            <v-col cols="4" class="text-center" align-self="center">
              <span>
                Datum:
                <br />
                <b>{{ parseDate1(b.zutritt.datum) }}</b>
              </span>
            </v-col>
            <v-col cols="4" class="text-center" align-self="center">
              <span>
                Zutritt:
                <br />
                <b>{{ b.zutritt.uhrzeit }} UHR</b>
              </span>
            </v-col>
            <v-col cols="4" class="text-center" align-self="center">
              <span>
                Verlassen:
                <br />
                <b>{{ b.verlassen.uhrzeit }} UHR</b>
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../firebaseConfig'

export default {
  name: 'Start',
  data() {
    return {
      besuche: [],
      date: '',
      datum: '',
      tabs: 1,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    var today = new Date()
    this.datum =
      today.getFullYear() +
      '-' +
      this.addNull(today.getMonth() + 1) +
      '-' +
      this.addNull(today.getDate())

    this.endedatum = this.datum
    firebase
      .firestore()
      .collection('User')
      .doc(this.user.uid)
      .collection('Besuche')
      .orderBy('zutritt.datum', 'desc')
      .orderBy('zutritt.uhrzeit', 'desc')
      .onSnapshot(
        (querySnap) => {
          this.besuche = []
          querySnap.forEach((doc) => {
            this.besuche.push(doc.data())
            var index = this.besuche.length - 1
            this.besuche[index].id = doc.id
          })
        },
        (error) => {
          console.log(error)
        },
      )
  },
  methods: {
    addNull(num) {
      if (num > 10) {
        return num
      }
      return '0' + num
    },
    parseDate(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      var weekdays = [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ]
      var weekday = datum_obj.getDay()
      return (
        weekdays[weekday] + ', ' + datum[2] + '.' + datum[1] + '.' + datum[0]
      )
    },
    parseDate1(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
  },
}
</script>
